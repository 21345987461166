/*

    :: DOCS [VerCode x Allsenses] ::

    ? How to use it ?
    To use it you need to add the data attribute "data-scrollanimation" to the element you want to animate,
    with the unique animation name as value.

    data-scrollanimation="[UNIQUE ANIMATION NAME]" - Declare a unique animation name for the element.


    * Example *
    <div data-scrollanimation="animation1"></div>




    ? What type of animations are available ?
    You can use all css properties, like "transform", "opacity", "width", etc.
    To declare dynamic values you can use the following scheme:

    |X to Y| - where X is the start value and Y is the end value, and requires the space before and after the "to".
    data-sa-[CSS PROPERTY] - to specify the css property you want to animate.


    * Example *
    <div data-scrollanimation="animation1" data-sa-opacity="|0 to 1|" data-sa-width="|200 to 500|px">




    ? How to use keyframes ?
    To use keyframes first of all you need to know how that works.
    Keyframes are a way to animate an element in a specific time, and you can use it to create a more complex animation.

    data-sa-[CSS PROPERTY]="|0:Q to a:W to b:E to c:R to d:T to 100:Y|"

    Where "Q, W, E, R, T, Y" are the values of the keyframes, and "0, a, b, c, d, 100" are the percentages of the animation.
    The numbers must be in order, and the values can be in any order. You must always use the ":" between the number and the value.
    And start from 0 and end with 100.


    * Example *
    <div data-scrollanimation="animation1" data-sa-width="|0:200 to 30:150 to 80:700 to 100:500|px"></div>     --> This will animate the width of the element from
                                                                                                                200px at 0% to 150px at 30% to 700px at 80% to 500px at 100%.

 */

export class VcAnimation {

    constructor(element, data) {
        this._element = element;
        this._mainElement = element;
        this._container = document.body;
        this._data = data || {};

        this.status = false;
        this.type = this._data.type || VcAnimation.ANIMATION_TYPES.SCROLL;
        this.progress = 0;
        this.lastProgress = 0;

        this.duration = this._data.duration || 0;
        this.animation = this._data.animation || "linear";
        this.offset = this._data.offset || 0;
        this.defOffset = this.offset

        this.style = this._data.style || {};
        this.debugMode = this._data.debug || false;

        this.selector = this._data.selector || null;

        if (this.selector) {
            if (this.selector.startsWith("|")) {
                this.selector = this.selector.substring(1);
                this._element = $(this.selector);
            } else {
                this._element = $(this._element).find(this.selector);
            }

            if (this._element.length === 0) {
                console.error("VcAnimation: selector not found");
                console.log(element, this.selector);
                return false;
            } else {
                this._element = this._element[0];
            }
        }

        let ct = $(this._element).findParent(".sticky-container");
        if (ct) {
            this._container = ct;
        }

        this.start();
    }

    start() {
        this.status = true;
        if (!this.registered) this.register();
        this.animate();
        this.calculate();

        let transition = $(this._element).css("transition");

        for (const st in this.style) {
            transition += `, ${st} ${this.animation} ${this.duration}ms`;
        }
        if (transition.startsWith(", ")) transition = transition.substring(2);

        $(this._element).css("transition", transition);
    }

    stop() {
        this.status = false;
    }

    animate() {
        if (!this.status) return;
        requestAnimationFrame(() => {
            this.animate();
        });

        if (this.progress === this.lastProgress) return;
        this.lastProgress = this.progress;

        this.calculate();
    }

    calculate() {
        let styles = this.style;
        let progress = this.progress;

        for (const styleKey in styles) {
            const styleValue = styles[styleKey];

            let regex = /(?<=\|)(.*?)(?=\|)/g;
            let matches = styleValue.match(regex);

            const replaces = [];

            if (matches != null) {
                for (const matchKey in matches) {
                    const matchValue = matches[matchKey];
                    let matchSplit = matchValue.split(" to ");

                    if (Array.isArray(matchSplit)) {
                        let value = 0;

                        if (matchSplit.length === 1) {
                            continue;
                        }

                        if (matchSplit.length === 2) {
                            value = parseFloat(matchSplit[0]) + (parseFloat(matchSplit[1]) - parseFloat(matchSplit[0])) * progress;
                        } else if (matchSplit.length > 2) {
                            let fromToArray = matchSplit;
                            let fromValue = parseFloat(fromToArray[0].split(":")[1]);

                            let xa = 0;
                            let ya = fromValue;
                            let xb = 1;
                            let yb = 1;

                            for (let keyPointIndex = 0; keyPointIndex < fromToArray.length; keyPointIndex++) {
                                const keypoint = fromToArray[keyPointIndex];
                                if (keyPointIndex < fromToArray.length - 1) {
                                    const positionCurrent = parseFloat(keypoint.split(':')[0]) / 100;
                                    const positionNext = parseFloat(fromToArray[keyPointIndex + 1].split(':')[0]) / 100;

                                    if (progress >= positionCurrent && progress <= positionNext) {
                                        xa = positionCurrent;
                                        ya = parseFloat(keypoint.split(':')[1]);
                                        xb = positionNext;
                                        yb = parseFloat(fromToArray[keyPointIndex + 1].split(':')[1]);
                                    }
                                }
                            }

                            const coef = (yb - ya) / (xb - xa);
                            const y0 = yb - coef * xb;
                            value = coef * progress + y0;
                        }

                        if (isNaN(value)) {
                            if (progress == 0) {
                                replaces.push({
                                    from: matchValue,
                                    to: matchSplit[0],
                                });
                            } else {
                                replaces.push({
                                    from: matchValue,
                                    to: matchSplit[1],
                                });
                            }
                        } else {
                            replaces.push({
                                from: matchValue,
                                to: value,
                            });
                        }


                    }
                }
            }


            let newStyleValue = styleValue;
            for (const replaceKey in replaces) {
                const replaceValue = replaces[replaceKey];
                newStyleValue = newStyleValue.replace(`|${replaceValue.from}|`, replaceValue.to);
            }

            $(this._element).css(styleKey, newStyleValue);
        }

    }

    debug() {
        this.debugMode = true;

        // Remove old debug element
        let oldDebugElement = this._mainElement.querySelector("#vc-animation-debug");
        if (oldDebugElement) oldDebugElement.remove();

        if (this._container !== document.body && this._container[0]) {
            this._container.style.outline = "3px solid orange";
            this._container.style.outlineOffset = "-3px";
        }

        if (this._mainElement) {
            this._mainElement.style.outline = "3px solid lime";
            this._mainElement.style.outlineOffset = "-3px";
        }

        this._mainElement.style.position = "relative";

        // Create element and append to element with data
        let debugElement = document.createElement("div");
        debugElement.id = "vc-animation-debug";
        debugElement.style.position = "fixed";
        debugElement.style.top = "0";
        debugElement.style.left = "0";
        debugElement.style.width = "fit-content";
        debugElement.style.height = "fit-content";
        debugElement.style.padding = "8px";
        debugElement.style.backgroundColor = "rgba(0, 0, 0, 0.65)";
        debugElement.style.zIndex = "99";
        debugElement.style.display = "flex";
        debugElement.style.alignItems = "flex-start";
        debugElement.style.justifyContent = "center";
        debugElement.style.flexDirection = "column";
        debugElement.style.color = "white";
        debugElement.style.fontSize = "12px";

        let debugText = document.createElement("div");
        debugText.innerText = "VcAnimation";
        debugElement.appendChild(debugText);

        // Debug data

        let progress = document.createElement("div");
        progress.innerHTML = `<span style="color: #fff; font-weight: bold;">Progress:</span> ${this.progress.toFixed(3)}`;
        progress.style.fontSize = "10px";
        progress.style.opacity = "0.7";
        debugElement.appendChild(progress);

        let status = document.createElement("div");
        status.innerHTML = `<span style="color: #fff; font-weight: bold;">Status:</span> ${this.status}`;
        status.style.fontSize = "10px";
        status.style.opacity = "0.7";
        debugElement.appendChild(status);

        let type = document.createElement("div");
        type.innerHTML = `<span style="color: #fff; font-weight: bold;">Type:</span> ${this.type}`;
        type.style.fontSize = "10px";
        type.style.opacity = "0.7";
        debugElement.appendChild(type);

        let offset = document.createElement("div");
        offset.innerHTML = `<span style="color: #fff; font-weight: bold;">Offset:</span> ${this.offset}`;
        offset.style.fontSize = "10px";
        offset.style.opacity = "0.7";
        debugElement.appendChild(offset);

        let duration = document.createElement("div");
        duration.innerHTML = `<span style="color: #fff; font-weight: bold;">Duration:</span> ${this.duration}`;
        duration.style.fontSize = "10px";
        duration.style.opacity = "0.7";
        debugElement.appendChild(duration);

        let animation = document.createElement("div");
        animation.innerHTML = `<span style="color: #fff; font-weight: bold;">Animation:</span> ${this.animation}`;
        animation.style.fontSize = "10px";
        animation.style.opacity = "0.7";
        debugElement.appendChild(animation);




        this._element.appendChild(debugElement);
    }

    register() {
        this.regitered = true;

        if (this.type == VcAnimation.ANIMATION_TYPES.SCROLL) {
            $(window).on("scroll", () => {
                let scrollPosition = $(window).scrollTop();
                let windowHeight = window.document.documentElement.clientHeight;
                let elementPosition = $(this._mainElement).offset().top;
                let elementHeight = $(this._mainElement).height();
                let offset = parseInt(this.offset);
                // let elementProcent = (scrollPosition + windowHeight - elementPosition) / (windowHeight + elementHeight);
                let elementProcent = (scrollPosition + windowHeight - elementPosition - offset) / (windowHeight + elementHeight - offset);


                if (elementProcent > 0 && elementProcent < 1) {
                    this.progress = elementProcent;
                }

                if (this.debugMode) this.debug();
            });
        }

        if (this.type == VcAnimation.ANIMATION_TYPES.CLICK) {
            $(this._mainElement).on("pointerdown", () => {
                this.progress = 1;

                if (this.debugMode) this.debug();
            });

            $(this._mainElement).on("pointerup", () => {
                setTimeout(() => {
                    this.progress = 0;

                    if (this.debugMode) this.debug();
                }, this.duration);
            });

            $(this._mainElement).on("focus", () => {
                this.progress = 1;

                if (this.debugMode) this.debug();
            });

            $(this._mainElement).on("blur", () => {
                setTimeout(() => {
                    this.progress = 0;

                    if (this.debugMode) this.debug();
                }, this.duration);
            });
        }

        if (this.type == VcAnimation.ANIMATION_TYPES.HOVER) {
            $(this._mainElement).on("mouseenter", () => {
                this.progress = 1;

                if (this.debugMode) this.debug();
            });

            $(this._mainElement).on("mouseleave", () => {
                this.progress = 0;

                if (this.debugMode) this.debug();
            });
        }

        if (this.type == VcAnimation.ANIMATION_TYPES.STICKY) {
            $(window).on("scroll", () => {
                let containerRect = this._container.getBoundingClientRect();
                let windowHeight = window.document.documentElement.clientHeight;
                let containerY = containerRect.y - windowHeight;
                let containerHeight = containerRect.height;
                let offset = parseInt(this.offset)

                let progress = (containerY + offset) / (containerHeight + windowHeight - offset);
                progress = -progress;

                if (progress > 0 && progress < 1) {
                    this.progress = progress;
                }

                if (this.debugMode) this.debug();
            });
        }

    }
}


// Animation types enum
VcAnimation.ANIMATION_TYPES = {
    SCROLL: "scroll",
    CLICK: "click",
    HOVER: "hover",
    STICKY: "sticky",
};

window.VcAnimation = VcAnimation;