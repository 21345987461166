import {$} from "../utils/utils.js";

$(window).on("load",function(){
	aos()
	$(window).on("scroll",function(){
		aos()
	});
})

function aos() {
	$("[data-aos]").each(function(){
		if($(this).data("aos")!="" && $(this).isInViewport()){
			const that=this;
			const duration=$(this).data("aos-duration") || 500;
			const delay=$(this).data("aos-delay") || 0;
			$(this).css("-webkit-transition-duration",`${duration}ms`);
			setTimeout(function(){
				$(that).data("aos","");
			},delay)
		}
	});
}