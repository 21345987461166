export class Tag {
    constructor({
                    tag = "div",
                    classList = [],
                    id = "",
                    text = "",
                    html = "",
                    data = {},
                    attr = {},
                    style = {},
                    events = {},
                    type = "",
                    name = "",
                }) {

        let elem = document.createElement(tag);

        if (id) elem.id = id;
        if (classList) elem.classList.add(...classList);
        if (text) elem.innerText = text;
        if (html) elem.innerHTML = html;
        if (data) {
            for (let key in data) {
                elem.dataset[key] = data[key];
            }
        }
        if (attr) {
            for (let key in attr) {
                elem.setAttribute(key, attr[key]);
            }
        }
        if (events) {
            for (let key in events) {
                elem.addEventListener(key, events[key]);
            }
        }
        if (style) {
            for (let key in style) {
                elem.style.setProperty(key, style[key]);
            }
        }
        if (type) elem.type = type;
        if (name) elem.name = name;

        // Functions
        elem.html = function(html) {
            this.innerHTML = html;
        };

        elem.create = function() {
            document.body.append(this);
        };

        elem.on = function(event, callback) {
            this.addEventListener(event, callback);
        };

        return elem;
    }
}