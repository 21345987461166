$(window).on("load",function(){
    // Utils for CSS data

    let cssRootsVariables;
    function getRootsVaribles() {
        if (cssRootsVariables) return cssRootsVariables;

        var styleSheet = document.styleSheets;
        var variables = {};
        for (var i = 0; i < styleSheet.length; i++) {
            var rules = styleSheet[i].cssRules;
            for (var j = 0; j < rules.length; j++) {
                var rule = rules[j];
                if (rule.selectorText == ":root") {
                    var declarations = rule.style;
                    for (var k = 0; k < declarations.length; k++) {
                        var declaration = declarations[k];
                        variables[declaration] = declarations.getPropertyValue(declaration).trimStart();
                    }
                }
            }
        }
        cssRootsVariables = variables;
        return variables;
    }

    window.css = {

        getVar: function(name) {
            return getRootsVaribles()[name];
        },

        getVars: function(startsWith = "") {
            let vars = {};
            for (let key in getRootsVaribles()) {
                if (key.startsWith(startsWith)) vars[key] = getRootsVaribles()[key];
            }
            return vars;
        },

        getBreakpointTypes: function() {
            return this.getVar("--grid-type").split(" ");
        },

        getBreakpoint: function() {
            const gridSizes = this.getVar("--grid-size").split(" ");
            const gridTypes = this.getVar("--grid-type").split(" ");

            let name = gridTypes[0];
            let size = parseInt(gridSizes[0]);
            gridSizes.forEach((s, index) => {
                s = parseInt(s);
                if (window.innerWidth >= s) {
                    name = gridTypes[index];
                    size = s;
                }
            });

            return { name, size };
        },

        getBreakpointSize: function(type) {
            const gridSizes = this.getVar("--grid-size").split(" ");
            const gridTypes = this.getVar("--grid-type").split(" ");

            let index = gridTypes.indexOf(type);
            if (index == -1) return 0;
            let size = parseInt(gridSizes[index]);

            return size;
        },

        onBreakpointChange: function(callback) {
            window.addEventListener("resize", function() {
                let bp = css.getBreakpoint();
                if (activeBreakpoint.name != bp.name) {
                    activeBreakpoint = bp;
                    callback(bp.name, bp.size);
                }
            });
        }

    }

    let activeBreakpoint = css.getBreakpoint();

    // Create event for breakpoint change
    css.onBreakpointChange(function(name, size) {
        const event = new Event("breakpointChange")
        event.name = name;
        event.size = size;
        window.dispatchEvent(event);
    });

},{once:true});