class Script{
	constructor(src="",type="text/javascript", callback){
		const script=document.createElement("script");
		script.src=src;
		script.type=type;
		script.async=true;
		script.defer=true;
		script.onload=callback;
		//script.crossOrigin="anonymous";
		document.head.append(script);
		return script;
	}
}

window.addScript = function(src, type = "text/javascript", callback = function() {}) {
    new Script(src, type, callback);
};

