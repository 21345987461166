class GoogleMap {

    constructor(obj, lat, lng, zoom) {
        this.obj = obj;
        this.lat = lat;
        this.lng = lng;
        this.zoom = zoom;
    }

    initMap() {
        this.map = new google.maps.Map(this.obj, {
            center: {lat: this.lat, lng: this.lng},
            zoom: this.zoom,
        });

        this.obj.style.minHeight = "300px";
    }

    addMarker(lat, lng, icon, title, content) {
        let marker = new google.maps.Marker({
            position: {lat: lat, lng: lng},
            map: this.map,
            title: title,
            icon: icon ? icon : null,
        });

        if (content) {
            let infoWindow = new google.maps.InfoWindow({
                content: content,
            });

            marker.addListener('click', function() {
                infoWindow.open(this.map, marker);
            });
        } else {
            marker.addListener('click', function() {
                this.map.setZoom(12);
                this.map.setCenter(marker.getPosition());
            });

            marker.addListener('dblclick', function() {
                // Open new tab
                window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
            });
        }
    }

    addMarkerByAddress(address, icon, title, content) {
        let url = `https://maps.googleapis.com/maps/api/geocode/json?key=${googleApiKey}&address=${address}`;

        fetch(url)
        .then(response => response.json())
        .then(data => {
            let lat = data.results[0].geometry.location.lat;
            let lng = data.results[0].geometry.location.lng;
            this.addMarker(lat, lng, icon, title, content);
        });
    }

    setCenterByAddress(address) {
        let url = `https://maps.googleapis.com/maps/api/geocode/json?key=${googleApiKey}&address=${address}`;

        fetch(url)
        .then(response => response.json())
        .then(data => {
            let lat = data.results[0].geometry.location.lat;
            let lng = data.results[0].geometry.location.lng;
            this.setCenter(lat, lng);
        });
    }

    addClass(className) {
        this.obj.classList.add(className);
    }

    setOptions(property, value) {
        this.map.setOptions({property: value});
    }

    changeStyle(style) {
        this.map.setOptions({styles: style});
    }

    setZoom(zoom) {
        this.map.setZoom(zoom);
    }

    setCenter(lat, lng) {
        this.map.setCenter({lat: lat, lng: lng});
    }

    setTilt(tilt) {
        this.map.setTilt(tilt);
    }

    setDraggable(draggable) {
        this.map.setOptions({draggable: draggable});
    }

    setScrollwheel(scrollwheel) {
        this.map.setOptions({scrollwheel: scrollwheel});
    }

    setDisableDoubleClickZoom(disableDoubleClickZoom) {
        this.map.setOptions({disableDoubleClickZoom: disableDoubleClickZoom});
    }

    setZoomControl(zoomControl) {
        this.map.setOptions({zoomControl: zoomControl});
    }

    setMapTypeControl(mapTypeControl) {
        this.map.setOptions({mapTypeControl: mapTypeControl});
    }

    setScaleControl(scaleControl) {
        this.map.setOptions({scaleControl: scaleControl});
    }

    setStreetViewControl(streetViewControl) {
        this.map.setOptions({streetViewControl: streetViewControl});
    }

    setRotateControl(rotateControl) {
        this.map.setOptions({rotateControl: rotateControl});
    }

    setFullscreenControl(fullscreenControl) {
        this.map.setOptions({fullscreenControl: fullscreenControl});
    }

    setMapTypeControlOptions(mapTypeControlOptions) {
        this.map.setOptions({mapTypeControlOptions: mapTypeControlOptions});
    }

    setHeight(height) {
        this.obj.style.minHeight = height;
    }

}

window.GoogleMap = GoogleMap;