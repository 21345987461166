window.googleApiKey = "AIzaSyB9d5Gnuu7J3pfuxOSvNA4TIOTEnGHbTwU";

// *** Utils ***
import "./js/utils/translate.js";
import "./js/utils/utils.js";
import "./js/editor-front.js";
import "./js/utils/loader.js";
import "./js/app.js";
import "./js/cookies.js";
import "./js/utils/css.js";
import "./js/utils/modal.js";

// *** Tools ***
//import "./swiper/js/swiper.js";
import "./js/tools/scrolleasing.js";
import "./js/tools/scrolleasing.js";
import "./js/tools/typinganimation.js";
import "./js/tools/vc-animation.js";
import "./js/tools/dynamic-components.js";
import "./js/tools/aos.js";
import "./js/tools/dotlottie-player.js";
import "./js/tools/vc-map.js";
import "./js/tools/vc-carousel.js";
import "./js/tools/vc-counter.js";

import "./js/tools/nav.js";
import "./js/watcher.js";
// import "./allsenses/js/fullpagescroll.js"

addScript(`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&callback=mapInit`);


// Cookies consent - load cookies after accept
window.addEventListener("cookie.accepted", function(event) {

    if (checkCookie("analyticalCookies")) {
        // Load analytical cookies
        console.log("Load analytical cookies");
        addScript("/scripts/js/tools/analytics/bowser.js", "text/javascript", function() {
            addScript("/scripts/js/tools/analytics/analytics.js", "module")
        })
    }

    if (checkCookie("marketingCookies")) {
        // Load marketing cookies
        console.log("Load marketing cookies");
    }

});

window.getComputedStyleByGlobalCompositeOperatorWithExclude = function(key, value) {
    return getComputedStyle(document.body)[key].split(value).join("");
};


setTimeout(function(){
	$(window).trigger("load");
},100);