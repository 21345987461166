const defaultLanguage = document.documentElement.lang;


// Read lang file
const { translates } = await import(`/scripts/js/langs/${defaultLanguage}.js`).then(module => module) || {};

// Translate
export function translate(key) {
    if (!translates[key]) console.warn(`[${defaultLanguage}] Missing translation for "${key}"`);
    return translates[key] || key;
}