import { Tag } from './utils/tag.js';
import { translate } from './utils/translate.js';

window.cookiesTypes = {
    functionalCookies: {
        name: translate("Functional cookies"),
        description: translate("Cookies that enable basic website functions such as navigation and access to secure areas of the website. Without these cookies, the website cannot function properly."),
        required: true,
    },
    analyticalCookies: {
        name: translate("Analytical cookies"),
        description: translate("Cookies that allow us to analyze how users use the site so that we can measure and improve the performance of the site."),
    },
    marketingCookies: {
        name: translate("Marketing cookies"),
        description: translate("Cookies that enable the delivery of advertising content to users that is more tailored to their interests. Without these cookies, online advertising will not be as relevant to you."),
    },
};

const initCookie = function() {
    let cookieModal = new Modal({disableClose: true, stickBottom: true, classList: ['p-6', 'p-8-md']});

    cookieModal.append(new Tag({
        tag: "h5",
        classList: ["cookie-title", "font-weight-semibold", "mb-4"],
        text: translate("This website uses cookies"),
    }))

    for (let key in cookiesTypes) {
        let cookie = cookiesTypes[key];

        let label = new Tag({
            tag: "label",
            classList: ["checkbox-label"],
        });

        let input = new Tag({
            tag: "input",
            type: "checkbox",
            name: key,
            classList: ["checkbox"],
        });
        if (cookie.required) {
            input.checked = true;
            input.disabled = true;
        }

        let indicator = new Tag({
            tag: "span",
            classList: ["indicator"],
        });

        let span = new Tag({
            tag: "span",
            classList: ["label"],
            html: `<span class="font-weight-semibold">${cookie.name}</span><br><span class="small mt-1 display-inline-block">${cookie.description}</span>`,
        });

        label.append(input);
        label.append(indicator);
        label.append(span);
        cookieModal.append(label);
    }

    let buttonGrid = new Tag({
        tag: "div",
        classList: ["grid-x", "container", "mt-4"],
        style: {
            "--gap": "3px",
            "gap": "12px"
        }
    });

    let selectedButton = new Tag({
        tag: "button",
        classList: ["cell", "md-6", "sm-12", "button", "primary", "cookie-button", "outline", "gray-50a"],
        text: translate("Save selected"),
    });

    selectedButton.on("click", function() {
        let cookie = {};
        for (let key in cookiesTypes) {
            cookie[key] = document.querySelector(`input[name="${key}"]`).checked;
        }

        localStorage.setItem("cookie", JSON.stringify(cookie));
        cookieModal.hide();
        cookieModal.remove();

        $(window).trigger("cookie.accepted", {cookie: cookie});
    });



    let allButton = new Tag({
        tag: "button",
        classList: ["cell", "md-6", "sm-12", "button", "primary", "cookie-button"],
        text: translate("Accept all"),
    });

    allButton.on("click", function() {
        let cookie = {};
        for (let key in cookiesTypes) {
            cookie[key] = true;
        }

        localStorage.setItem("cookie", JSON.stringify(cookie));
        cookieModal.hide();
        cookieModal.remove();

        $(window).trigger("cookie.accepted", {cookie: cookie});
    });

    buttonGrid.append(selectedButton);
    buttonGrid.append(allButton);
    cookieModal.append(buttonGrid);

    cookieModal.show();

}

window.addEventListener("load", function() {
    let cookie = JSON.parse(localStorage.getItem("cookie"));

    if (!cookie) {
        initCookie();
        return;
    }

    for (let key in cookiesTypes) {
        if (cookie[key] == undefined) {
            initCookie();
            return;
        }
    }

    $(window).trigger("cookie.accepted", {cookie: cookie});
},{once:true});

window.checkCookie = function(name) {
    if (name) {
        let cookie = JSON.parse(localStorage.getItem("cookie"));
        if (cookie) {
            return cookie[name];
        }
    }

    return JSON.parse(localStorage.getItem("cookie"));
}