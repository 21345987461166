window.vcCarousel = [];

$(window).on("load", function() {
    $(`.grid-carousel:not(.inited)`).each(function() {
        let settings = $(this).data("carousel");
        settings = settings ? JSON.parse(settings) : {};
        vcCarousel.push(new VcCarousel(this, settings.gap || null, settings.scrollEnabled || false, settings.snapEnabled || false));
    });
},{once:true});


class VcCarousel {

    constructor(slider, gap = null, scrollEnabled = false, snapEnabled = false) {

        this.slider = slider;
        this.isDown = false;
        this.startX = 0;
        this.scrollLeft = 0;
        this.gap = gap;
        if (this.gap) this.gap = parseInt(this.gap);

        this.scrollEnabled = scrollEnabled;
        this.snapEnabled = snapEnabled;

        this.init();
        this.events();
        this.snap();
    }

    init() {
        if (this.gap) {
            $(this.slider).css('--gap', `${this.gap}px`);
        }

        if (this.scrollEnabled) {
            setInterval(() => {
                if (this.lastScroll && this.lastScroll != -1 && Date.now() - this.lastScroll > 1000) {
                    this.snap();
                    this.lastScroll = -1;
                }
            }, 250);
        }
    }

    events() {
        const that = this;
        const slider = this.slider;

        window.addEventListener('resize', () => {
            that.snap();
        });

        slider.addEventListener('mouseup', () => {
            that.isDown = false;
            slider.classList.remove('active');
            that.snap();
        });

        slider.addEventListener('mousedown', (e) => {
            that.isDown = true;
            slider.classList.add('active');
            that.startX = e.pageX - slider.offsetLeft;
            that.scrollLeft = slider.scrollLeft;
        });

        slider.addEventListener('mouseleave', () => {
            if (!that.isDown) return;
            that.isDown = false;
            slider.classList.remove('active');
            that.snap();
        });

        slider.addEventListener('mousemove', (e) => {
            if (!that.isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - that.startX); //scroll-fast
            slider.scrollLeft = that.scrollLeft - walk;
        });

        slider.addEventListener('touchstart', (e) => {
            that.isDown = true;
            slider.classList.add('active');
            that.startX = e.touches[0].pageX - slider.offsetLeft;
            that.scrollLeft = slider.scrollLeft;
        });

        slider.addEventListener('touchend', () => {
            that.isDown = false;
            slider.classList.remove('active');
            that.snap();
        });

        slider.addEventListener('touchmove', (e) => {
            if (!that.isDown) return;
            e.preventDefault();
            const x = e.touches[0].pageX - slider.offsetLeft;
            const walk = (x - that.startX); //scroll-fast
            slider.scrollLeft = that.scrollLeft - walk;
        });

        if (!this.scrollEnabled) return;
        slider.addEventListener('wheel', (e) => {
            e.preventDefault();
            that.lastScroll = Date.now();
            slider.scrollTo({
                left: slider.scrollLeft + (e.deltaY * 2),
                behavior: 'smooth',
            });
        });

    }
    
    snap() {
        if (!this.snapEnabled) return;

        const slider = this.slider;
        let closest = null;

        let children = slider.children;
        for (let i = 0; i < children.length; i++) {
            let child = children[i];
            if (closest == null || Math.abs(child.offsetLeft - slider.scrollLeft) < Math.abs(closest.offsetLeft - slider.scrollLeft)) {
                closest = child;
            }
        }
        
        let lastElement = children[children.length - 1];
        if (lastElement.offsetLeft + lastElement.offsetWidth - slider.scrollLeft < slider.offsetWidth) {
            closest = lastElement;
        }

        for (let i = 0; i < children.length; i++) {
            let child = children[i];
            child.classList.remove("active");
        }

        // Add border to the closest card
        if (closest) {
            closest.classList.add("active");
            // Scroll sliderx to the closest card
            setTimeout(function() {
                slider.scrollTo({
                    left: closest.offsetLeft,
                    behavior: 'smooth',
                })
            }, 250);
        }
    }


}